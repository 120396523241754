<!--
 * @Author: WeChat: TashiDelek668
 * @Date: 2021-01-12 18:28:39
 * @LastEditors: TashiDelek
 * @LastEditTime: 2021-01-15 16:46:03
 * @FilePath: \hr_admin_2.0\src\views\position\components\protect-dialog.vue
-->
<template>
	<div class="telephone">
		<el-dialog
			v-model="dialogVisible"
			:lock-scroll="true"
			:before-close="close"
			width="460px"
			top="20vh"
		>
			<template v-slot:title>
				<div class="flex_c">
					<!-- <p class="el-icon-warning fs_24 mr_5"></p> -->
					<p class="fs_18 bold">温馨提示</p>
				</div>
			</template>
			<div class="center content">
				<div class="padding_20">
					<p class="fs_16 color_000">
						入职保护需要花费 {{ data.pay_credit }} CRC，是否继续？
					</p>
					<p class="fs_14 mt_10 sub_color">当前剩余{{ data.balance }}CRC</p>
				</div>
			</div>
			<template v-slot:footer>
				<div class="flex_around">
					<el-button type="info" size="medium" @click="close">取 消</el-button>
					<el-button type="primary" size="medium" :loading="loading" @click="check">
						继 续
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			id: "",
			index: 0,
			data: { pay_credit: 0.0, balance: 0.0 },
			dialogVisible: false,
			loading: false,
			baseURL: "/ent/v3/job/protect_user/"
		}
	},

	methods: {
		close() {
			this.dialogVisible = false
		},
		init() {
			this.$axios({
				url: this.baseURL + this.id,
				method: "GET",
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.dialogVisible = true
					this.data = res.data
				}
			})
		},
		check() {
			this.loading = true
			this.$axios({
				url: this.baseURL + this.id,
				method: "PUT",
				shake: true,
				hideLoading: true
			}).then(res => {
				setTimeout(() => {
					this.loading = false
				}, 900)
				if (res.code === 200) {
					this.$parent.list.splice(this.index, 1)
					this.$parent.data.interview_num--
					this.$parent.data.join_num++
					this.$message({
						dangerouslyUseHTMLString: true,
						duration: 2000,
						customClass: "crc_change_tip",
						message:
							'<div class="flex_cen fs_18" ><p class="flex_1 crc-icon"></p><p class="FFAD20">成功扣除' +
							this.data.pay_credit +
							"CRC</p></div>"
					})
					this.close()
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
.el-icon-warning {
	color: #ff9900;
}
/deep/.el-dialog__header {
	padding: 20px;
}
/deep/.el-dialog__body {
	padding: 0;
}
.content {
	border-top: 1px solid #ddd;
	height: 168px;
	padding: 30px;
}
/deep/.el-button {
	width: 162px;
	height: 42px;
	font-size: 16px;
}
</style>
